import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import s0 from "../image/app/service/s0.jpg";
import s1 from "../image/app/service/s1.jpg";
import s2 from "../image/app/service/s2.jpg";
import s3 from "../image/app/service/s3.jpg";
import s4 from "../image/app/service/s4.jpg";
import s5 from "../image/app/service/s5.jpg";
import s6 from "../image/app/service/s6.jpg";
import s7 from "../image/app/service/s7.jpg";
import s8 from "../image/app/service/s8.jpg";
import s9 from "../image/app/service/s9.jpg";
import s10 from "../image/app/service/s10.jpg";
import s11 from "../image/app/service/s11.jpg";

class ServiceGPT extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [
        { url: s0, textId: "services.main.0", customText: "services.main.0.0" },
        { url: s1, textId: "services.main.1", customText: "services.main.1.0" },
        { url: s2, textId: "services.main.2", customText: "services.main.2.0" },
        { url: s3, textId: "services.main.3", customText: "services.main.3.0" },
        { url: s4, textId: "services.main.4", customText: "services.main.4.0" },
        { url: s5, textId: "services.main.5", customText: "services.main.5.0" },
        { url: s6, textId: "services.main.6", customText: "services.main.6.0" },
        { url: s7, textId: "services.main.7", customText: "services.main.7.0" },
        { url: s8, textId: "services.main.8", customText: "services.main.8.0" },
        { url: s9, textId: "services.main.9", customText: "services.main.9.0" },
        {
          url: s10,
          textId: "services.main.10",
          customText: "services.main.10.0",
        },
        {
          url: s11,
          textId: "services.main.11",
          customText: "services.main.11.0",
        },
      ],
    };
  }

  handleMouseEnter = (index) => {
    const { images } = this.state;
    const updatedImages = [...images];
    updatedImages[index].textId = `services.main.${index}.0`;
    this.setState({ images: updatedImages });
  };

  handleMouseLeave = (index) => {
    const { images } = this.state;
    const updatedImages = [...images];
    updatedImages[index].textId = `services.main.${index}`;
    this.setState({ images: updatedImages });
  };

  render() {
    const isMobile = window.innerWidth <= 768;

    const divStyle = {
      backgroundColor: "#FFFFE0",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: "100%",
      marginTop: "30px",
      marginBottom: "30px",
    };

    return (
      <div
        id="service"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#f8f9fa",
        }}
      >
        {Array.from({ length: 4 }).map((_, rowIndex) => (
          <div
            key={rowIndex}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: isMobile ? "column" : "row", // On mobile, change the layout to column
              marginBottom: isMobile ? "30px" : 0, // Add margin-bottom for spacing on mobile
            }}
          >
            {this.state.images
              .slice(rowIndex * 3, (rowIndex + 1) * 3)
              .map((image, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    margin: isMobile ? "10px 0" : "10px", // Adjust margins on mobile
                    width: isMobile ? "250px" : "350px", // Reduce width on mobile
                  }}
                  onMouseEnter={() =>
                    this.handleMouseEnter(rowIndex * 3 + index)
                  }
                  onMouseLeave={() =>
                    this.handleMouseLeave(rowIndex * 3 + index)
                  }
                >
                  <img
                    src={image.url}
                    alt={`Image ${index}`}
                    style={{ width: "100%", borderRadius: "4px" }} // Add border radius to the image
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      fontWeight: "bold",
                      textAlign: "center",
                      background: "rgba(0, 0, 0, 0.4)",
                      padding: "20px", // Increase the padding to make the box bigger
                      borderRadius: "4px",
                      fontSize: "18px", // Increase the font size for better visibility
                      margin: "-10px", // Set negative margin to expand the box beyond the image
                      transition: "0.3s",
                    }}
                  >
                    <FormattedMessage
                      id={image.textId}
                      defaultMessage={image.textId}
                    />
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>
    );
  }
}

export default ServiceGPT;
