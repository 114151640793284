import React, { Component } from "react";
import Zoom from "react-reveal/Zoom";
import logo from "../image/app/LogoHeads_Gray.png";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
//import footerImg from "../image/app/footer.png";

class Footer extends Component {
  render() {
    const divStyle = {
      //backgroundImage: "url(" + footerImg + ")",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      //height: "80%",
      color: "#f5f5f5",
    };
    return (
      <section className="footer-area">
        <div className="container">
          <div>
            <div className="row footer-content">
              <div className="col-lg-4 col-sm-4">
                <Zoom top cascade>
                  <div>
                    <img src={logo} alt="Logo" style={{ height: "17vh" }} />
                  </div>
                </Zoom>
              </div>

              <div className="col-lg-4 col-sm-4" style={{ fontWeight: "bold" }}>
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                    <FormattedMessage
                      id="contact.info.address.1"
                      defaultMessage=""
                    />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faEnvelope} />{" "}
                    <FormattedMessage
                      id="contact.info.email.1"
                      defaultMessage=""
                    />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPhone} />{" "}
                    <FormattedMessage
                      id="contact.info.phone.1"
                      defaultMessage=""
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12" style={{ textAlign: "center" }}>
                <p>
                  &copy; {new Date().getFullYear()} Kozanowska.com Wszystkie
                  prawa zastrzeżone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Footer;
