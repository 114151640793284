import axios from 'axios';

// export default axios.create({
//    baseURL: 'https://localhost:7271'
// });

export default axios.create({
    baseURL: 'http://kozanowska.com'
});

