import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Fade, Reveal } from 'react-reveal/';
//import aboutImg from '../image/app/about1.png';
import { FormattedMessage } from "react-intl";
import { FacebookProvider, Profile } from "react-facebook";
//import backImg from '../image/app/service/back.jpg';
//import backImg from '../image/app/service/back.jpg';
import backImg from "../image/app/LogoHeads_White_100.png";

class News extends Component {
  render() {
    const divStyle = {
      backgroundColor: "#f8f9fa",
      height: "40vh",
      color: "#f5f5f5",
    };

    const jumbotronStyle = {
      backgroundColor: "rgba(52, 52, 52, 0.4)",
    };

    return (
      <section className="contact-area" id="news" style={divStyle}>
        <div className="row">
          <div className="col">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  className="fb-page"
                  data-href="https://www.facebook.com/przychodniaweterynaryjnakozanowska/"
                  data-tabs=""
                  data-width="380"
                  data-height=""
                  data-small-header="false"
                  data-adapt-container-width="false"
                  data-hide-cover="false"
                  data-show-facepile="false"
                >
                  <blockquote
                    cite="https://www.facebook.com/przychodniaweterynaryjnakozanowska/"
                    className="fb-xfbml-parse-ignore"
                  >
                    <a href="https://www.facebook.com/przychodniaweterynaryjnakozanowska/">
                      Przychodnia Weterynaryjna Kozanowska
                    </a>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div>
              <div>
                <ul className="list-group">
                  <li className="paragraph">
                    <h2>
                      <FormattedMessage id="news.openHour" defaultMessage="" />
                    </h2>
                  </li>
                  <li className="paragraph">
                    <h5>
                      <FormattedMessage id="news.monday" defaultMessage="" /> -{" "}
                      <FormattedMessage id="news.friday" defaultMessage="" />{" "}
                      <span>09:00-19:00</span>
                    </h5>
                  </li>
                  <li className="paragraph">
                    <h5>
                      <FormattedMessage id="news.saturday" defaultMessage="" />{" "}
                      <span>10:00-14:00</span>
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default News;
