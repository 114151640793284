import React, { Component } from "react";
import Sticky from "react-stickynode";
import { Link as ScrollLink } from "react-scroll";
import { connect } from "react-redux";
//import { Link as SiteLink } from "react-router-dom";
//import Hamburger from "hamburger-react";
//application
import { localeChange } from "../../store/locale";

import { FormattedMessage } from "react-intl";

const flagPL = require("../../image/app/flags/poland.png");
const flagUA = require("../../image/app/flags/ukraine.png");

const photo = {
  height: "25px",
  marginLeft: "5px",
  cursor: "pointer",
};

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  handleLanguageChange = (x) => {
    this.props.localeChange(x);
  };

  // handleLogOut = (x) => {
  //   this.props.logOut();
  // };

  handleMenuClick = () => {
    console.log(this.state.menuOpen);
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  handleRedirectMenuClick = () => {
    //this.setState({ menuOpen: !this.state.menuOpen });
  };

  render() {
    var { mClass, mContainer, mainlogo, stickylogo } = this.props;
    const redirectBtnStyleStyle = {
      backgroundColor: "rgb(61, 60, 69)",
      font: '600 14px "Montserrat", sans- serif',
      border: "none",
      color: "white",
    };
    return (
      <Sticky top={0} innerZ={100} activeClass="navbar_fixed">
        <nav className={`navbar navbar-expand-lg navbar-light ${mClass}`}>
          <div className={`container ${mContainer}`}>
            <a className="navbar-brand logo_h" href="#">
              <img
                src={require("../../image/app/" + mainlogo)}
                alt=""
                style={{ height: "17vh" }}
              />
              <img
                src={require("../../image/app/" + stickylogo)}
                alt=""
                style={{ height: "17vh" }}
              />
            </a>

            <button
              className="navbar-toggler"
              type="button"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={this.state.menuOpen}
              aria-label="Toggle navigation"
              onClick={this.handleMenuClick}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>

            <div
              id="navbarSupportedContent"
              className={`collapse navbar-collapse ${
                this.state.menuOpen ? "show" : ""
              }`}
            >
              <ul className="nav navbar-nav m-auto">
                <li className="nav-item">
                  <ScrollLink
                    className="nav-link"
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                    onClick={this.handleMenuClick}
                  >
                    <FormattedMessage id="navbar.menu.home" defaultMessage="" />
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    className="nav-link"
                    activeClass="active"
                    to="news"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                    onClick={this.handleMenuClick}
                  >
                    <FormattedMessage
                      id="navbar.menu.testimonial"
                      defaultMessage=""
                    />
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    className="nav-link"
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                    onClick={this.handleMenuClick}
                  >
                    <FormattedMessage
                      id="navbar.menu.about"
                      defaultMessage=""
                    />
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    className="nav-link"
                    activeClass="active"
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                    onClick={this.handleMenuClick}
                  >
                    <FormattedMessage
                      id="navbar.menu.service"
                      defaultMessage=""
                    />
                  </ScrollLink>
                </li>

                <li className="nav-item">
                  <ScrollLink
                    className="nav-link"
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                    onClick={this.handleMenuClick}
                  >
                    <FormattedMessage
                      id="navbar.menu.contact"
                      defaultMessage=""
                    />
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    className="nav-link"
                    activeClass="active"
                    to=""
                    onClick={() =>
                      window.open(
                        "https://www.wettermin.pl/lecznice/wroclaw/3972",
                        "_blank"
                      )
                    }
                  >
                    <FormattedMessage
                      id="navbar.menu.appointment"
                      defaultMessage=""
                    />
                  </ScrollLink>
                </li>
                {/* <li className="nav-item">
                  <img
                    src={flagPL}
                    alt="..."
                    style={photo}
                    onClick={() => this.handleLanguageChange("pl")}
                  />
                  <img
                    src={flagUA}
                    alt="..."
                    style={photo}
                    onClick={() => this.handleLanguageChange("en")}
                  />
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </Sticky>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.locale,
});

const mapDispatchToProps = {
  localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
