import React, { Component } from "react";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
  }

  //displayMarkers = () => {
  //  return this.state.stores.map((store, index) => {
  //    return <Marker key={index} id={index} position={{
  //     lat: store.latitude,
  //     lng: store.longitude,
  //   }}
  //   onClick={() => console.log("You clicked me!")} />
  //  })
  //}

  render() {
    const mapStyles = {
      width: "100%",
      height: "500px",
    };
    return (
      <section className="contact_map">
        <iframe
          frameBorder="0"
          style={{ width: "100%", height: "100%" }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2503.3468621073516!2d16.96378531580251!3d51.13895444539591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fea661dd0603b%3A0x8dca85d5c9924c74!2sKozanowska%20117%2C%2054-152%20Wroc%C5%82aw!5e0!3m2!1spl!2spl!4v1614113467236!5m2!1spl!2spl"
        ></iframe>
      </section>
    );
  }
}

export default MapContainer;

//export default GoogleApiWrapper({
//  apiKey: 'AIzaSyBCB4y0SztfLo236n4JECwTvIQd-VecF2Y'
//})(MapContainer);

//<section className="contact_map">
//    <Map
//        google={this.props.google}
//        zoom={8}
//        initialCenter={{ lat: 47.444, lng: -122.176 }}
//        style={mapStyles}
//    >
//        {this.displayMarkers()}
//    </Map>
//</section>
