import React from "react";
import { connect } from "react-redux";
import Navbar from "./component/Navbar/Navbar";
import Banner from "./component/Banner/Banner";
import News from "./component/News";
import News3 from "./component/News3";
import About2 from "./component/About2";
import ServiceGpt from "./component/ServiceGpt";
import MapContainer from "./component/Map";
import Contact from "./component/Contact";
import Footer from "./component/Footer";

//import CookieConsent, { Cookies } from "react-cookie-consent";

class Home extends React.Component {
  render() {
    return (
      <div className="body_wrapper">
        <Navbar
          mContainer="custome_container"
          mainlogo="LogoHeads_White.png"
          stickylogo="LogoHeads_Gray.png"
        />
        <Banner />
        {/* <News /> */}
        <News3 />
        <About2 className="about_area" />
        <ServiceGpt />
        <MapContainer />
        <Contact />
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  //isSignedIn: state.auth.isSignedIn,
});

export default connect(mapStateToProps)(Home);
