import React, { Component } from "react";
import Zoom from "react-reveal/Zoom";
import { FormattedMessage } from "react-intl";
const image_10 = require("../../image/app/Banner10.jpg");

class Banner extends Component {
  render() {
    return (
      <section id="home">
        <div
          className="banner_area"
          style={{
            backgroundImage: "url(" + image_10 + ")",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <div
            className="container"
            style={{
              position: "absolute",
              top: "30%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="banner_content">
              {/* <Reveal effect="fadeInUp" duration={1500}> */}
              <div>
                <Zoom bottom cascade duration={1000}>
                  <h3 style={{ color: "white", fontSize: "2.3rem" }}>
                    {/* <FormattedMessage id="banner.dscr.1" defaultMessage="" />{" "} */}
                  </h3>
                </Zoom>
              </div>
              {/* </Reveal> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Banner;

//1.
//<Fade bottom cascade duration={1000}>
//    <h3 style={{ color: "white" }}>Przychodnia Weterynaryjna</h3>
//    <h2 style={{ color: "white" }}>Kozanowska</h2>
//</Fade>

//<Fade bottom cascade duration={1000}>
//    <h2 className="card-tittle" style={{ color: "white" }}>Przychodnia</h2>
//    <h2 className="card-tittle" style={{ color: "white" }}>Weterynaryjna</h2>
//    <h2 className="card-tittle" style={{ color: "white" }}>Kozanowska</h2>
//</Fade>

//<section id="home">
//    <Parallax className="banner_area" style={{
//        backgroundImage: 'url(' + image_2 + ')',
//        backgroundSize: "cover",
//        backgroundRepeat: "no-repeat",
//        height: "100%",
//        color: "#f5f5f5"
//    }}>
//        <div className="container">
//            <div className="banner_content">
//                <Reveal effect="fadeInUp" duration={1500}>
//                    <div>
//                        <img src={logo} alt="Logo" style={{ height: "10vh" }} />
//                    </div>
//                </Reveal>
//            </div>
//        </div>
//    </Parallax>
//</section>

//18:46 10.12.2020
//<section id="home">
//    <Parallax bgImage={image_2} className="banner_area">
//        <div className="container">
//            <div className="banner_content">
//                <Reveal effect="fadeInUp" duration={1500}>
//                    <div>
//                        <img src={logo} alt="Logo" style={{ height: "10vh" }} />
//                    </div>
//                </Reveal>
//            </div>
//        </div>
//    </Parallax>
//</section>

//style = {{
//    backgroundImage: 'url(' + image_2 + ')',
//        backgroundSize: "cover",
//            backgroundPosition: "center",
//                backgroundRepeat: "no-repeat",
//                    height: "100vh",
//                        color: "#f5f5f5"
//}}

//<div className="banner_content">
//    <Reveal effect="fadeInUp">
//        <h5>Witamy na stronie</h5>
//    </Reveal>
//    <Reveal effect="fadeInUp" duration={1500}>
//        <h2>Jan Kowalski</h2>
//    </Reveal>

//    <h4 className="wow fadeInUp anoimated">Visual Designer & Front-end Developer</h4>

//</div>

//<Fade bottom cascade duration={1000}>
//    <ul className="list_style social_icon">
//        {
//            jhonData.socialLinks && jhonData.socialLinks.map(item => {
//                return (
//                    <li key={item.name}>
//                        <a href={item.url}><i className={item.className}></i></a>
//                    </li>
//                )
//            }
//            )
//        }
//    </ul>
//</Fade>
