import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./store";
//import "./service.css";
//import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/elagent-icon/style.css";
import "./assets/etline-icon/style.css";
import "./assets/flaticon/flaticon.css";
import "./assets/css/animate.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
// import "bootstrap/dist/js/bootstrap.min.js";
// import "bootstrap/dist/js/bootstrap.bundle";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
