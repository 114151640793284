import React, { Component } from "react";
import Form from "./Form";
import Zoom from "react-reveal/Zoom";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faTrain,
  faBus,
} from "@fortawesome/free-solid-svg-icons";
import visa from "../image/app/Contact/visa.png";
import mastercard from "../image/app/Contact/mastercard.png";
import blik from "../image/app/Contact/blik.png";

const ulStyle = {
  listStyleType: "none",
};

class Contact extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <section className="contact-area" id="contact">
        <div className="container">
          <div className="row row-reverse">
            <React.Fragment>
              <div className="col-md-6">
                <Zoom top cascade>
                  <div className="contact_info">
                    <h5>
                      <FormattedMessage
                        id="contact.info.header"
                        defaultMessage=""
                      />
                    </h5>
                    <ul className="nav">
                      <li className="item">
                        <div className="row">
                          <div className="col col-lg-1">
                            <a href="/#" style={{ marginRight: "5px" }}>
                              <FontAwesomeIcon icon={faMapMarkerAlt} />
                            </a>
                          </div>
                          <div className="col">
                            <b>
                              <FormattedMessage
                                id="contact.info.address.1"
                                defaultMessage=""
                              />
                            </b>
                            <br />
                            <b>
                              <FormattedMessage
                                id="contact.info.address.2"
                                defaultMessage=""
                              />
                            </b>
                            <br />
                            <b>
                              <FormattedMessage
                                id="contact.info.address.3"
                                defaultMessage=""
                              />
                            </b>
                          </div>
                        </div>
                      </li>
                      <li className="item">
                        <div className="row">
                          <div className="col col-lg-1">
                            <a href="/#" style={{ marginRight: "5px" }}>
                              <FontAwesomeIcon icon={faEnvelope} />
                            </a>
                          </div>
                          <div className="col">
                            <b>
                              <FormattedMessage
                                id="contact.info.email.1"
                                defaultMessage=""
                              />
                            </b>
                          </div>
                        </div>
                      </li>
                      <li className="item">
                        <div className="row">
                          <div className="col col-lg-1">
                            <a href="/#" style={{ marginRight: "5px" }}>
                              <FontAwesomeIcon icon={faPhone} />
                            </a>
                          </div>
                          <div className="col">
                            <b>
                              <FormattedMessage
                                id="contact.info.phone.1"
                                defaultMessage=""
                              />
                            </b>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <hr />
                    <h5>
                      <FormattedMessage
                        id="contact.info.journey"
                        defaultMessage=""
                      />
                    </h5>
                    <ul className="nav">
                      <div className="row">
                        <div className="col col-lg-1">
                          <a href="/#" style={{ marginRight: "5px" }}>
                            <FontAwesomeIcon icon={faTrain} />
                          </a>
                        </div>
                        <div className="col">
                          <FormattedMessage
                            id="contact.info.journey.1"
                            defaultMessage=""
                          />
                          <br />
                          <FormattedMessage
                            id="contact.info.journey.2"
                            defaultMessage=""
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col col-lg-1">
                          <a href="/#" style={{ marginRight: "5px" }}>
                            <FontAwesomeIcon icon={faBus} />
                          </a>
                        </div>
                        <div className="col">
                          <FormattedMessage
                            id="contact.info.journey.3"
                            defaultMessage=""
                          />
                        </div>
                      </div>
                    </ul>
                    <hr />
                    <h5>
                      <FormattedMessage
                        id="contact.info.account"
                        defaultMessage=""
                      />
                    </h5>
                    <ul className="nav">
                      <li className="item">
                        <div className="media">
                          <span style={{ marginRight: "5px" }}>
                            <FormattedMessage
                              id="contact.info.account.1"
                              defaultMessage=""
                            />
                          </span>
                        </div>
                      </li>
                    </ul>
                    <hr />
                    <h5>
                      <FormattedMessage
                        id="contact.payments.header"
                        defaultMessage=""
                      />
                    </h5>
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        <img
                          src={visa}
                          alt="sample41"
                          style={{ width: "60px" }}
                        />
                        <img
                          src={mastercard}
                          alt="sample41"
                          style={{ width: "60px" }}
                        />
                        <img
                          src={blik}
                          alt="sample41"
                          style={{ width: "60px" }}
                        />
                      </li>
                    </ul>
                  </div>
                </Zoom>
              </div>
            </React.Fragment>
            <div className="col-md-6">
              <Zoom top cascade>
                <div className="input_form">
                  <h5>
                    <FormattedMessage
                      id="contact.form.header"
                      defaultMessage=""
                    />
                  </h5>
                  <Form />
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Contact;

//class Contact extends Component {
//    render() {
//        let jhonData = this.props.jhonData;
//        return (
//            <section className="contact-area" id="contact">
//                <div className="container">
//                    <div className="row row-reverse">
//                        {jhonData.contact &&
//                            jhonData.contact.map((item) => {
//                                return (
//                                    <React.Fragment key={item.id}>
//                                        <div className="col-md-5">
//                                            <Reveal effect="fadeInLeft" duration={500}>
//                                                <div className="contact_info">
//                                                    <h4>{item.title}</h4>
//                                                    <ul className="nav">
//                                                        {item.menuItems.map((info) => {
//                                                            return (
//                                                                <li className="item" key={info.id}>
//                                                                    <div className="media">
//                                                                        <a href="/#">
//                                                                            <i className={info.icon}></i>
//                                                                        </a>
//                                                                        <div className="media-body">
//                                                                            <a href="/#">{info.text}</a>
//                                                                        </div>
//                                                                    </div>
//                                                                </li>
//                                                            );
//                                                        })}
//                                                    </ul>
//                                                </div>
//                                            </Reveal>
//                                        </div>
//                                    </React.Fragment>
//                                );
//                            })}
//                        <div className="col-md-7">
//                            <Reveal effect="fadeInRight" duration={800}>
//                                <div className="input_form">
//                                    <h4>{jhonData.contacttitle}</h4>
//                                    <Form />
//                                    <div id="success">Your message succesfully sent!</div>
//                                    <div id="error">
//                                        Opps! There is something wrong. Please try again
//                  </div>
//                                </div>
//                            </Reveal>
//                        </div>
//                    </div>
//                </div>
//            </section>
//        );
//    }
//}
//export default Contact;
