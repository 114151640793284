import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as bootstrap from "bootstrap/dist/js/bootstrap";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import userApis from "../store/auth/authApis";

const errInputStyle = {
  border: "1px solid red",
};

const defaultInputStyle = {
  border: "1px solid #ced4da",
};

class Form extends React.Component {
  captchaRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      verified: false,
    };
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderInput = ({ input, label, isDisabled, meta }) => {
    return (
      <div>
        <label htmlFor={input.name}>{label}</label>
        <input
          id={input.name}
          name={input.name}
          type="text"
          className="form-control"
          style={meta.error && meta.touched ? errInputStyle : defaultInputStyle}
          onChange={input.onChange}
          value={input.value}
        />
      </div>
    );
  };

  renderEmail = ({ input, label, isDisabled, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className="field error">
        <label htmlFor="profile-first-name">{label}</label>
        <input
          id={input.name}
          name={input.name}
          type="email"
          className="form-control"
          style={meta.error && meta.touched ? errInputStyle : defaultInputStyle}
          onChange={input.onChange}
          value={input.value}
        />
      </div>
    );
  };

  renderTextArea = ({ input, label, isDisabled, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label htmlFor="profile-first-name">{label}</label>
        <textarea
          id={input.name}
          name={input.name}
          type="text"
          className="form-control"
          style={meta.error && meta.touched ? errInputStyle : defaultInputStyle}
          onChange={input.onChange}
          value={input.value}
        />
      </div>
    );
  };

  thisAllert = () => {
    return (
      <div className="alert alert-primary" role="alert">
        A simple primary alert with. Give it a click if you like.
      </div>
    );
  };

  onSubmit = (formValues) => {
    const toastSuccess = document.getElementById("liveToastSuccess");
    const toastWarn = document.getElementById("liveToastWarn");
    const toastInfo = document.getElementById("liveToastInfo");
    const success = bootstrap.Toast.getOrCreateInstance(toastSuccess);
    const warn = bootstrap.Toast.getOrCreateInstance(toastWarn);
    const info = bootstrap.Toast.getOrCreateInstance(toastInfo);

    const token = this.captchaRef.current.getValue();

    if (token) {
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      userApis
        .post("/Mail/SendMail", formValues, options)
        .then((response) => {
          success.show();
          this.captchaRef.current.reset();
        })
        .catch((err) => {
          warn.show();
          this.captchaRef.current.reset();
        });
    } else {
      info.show();
    }
  };

  verifyCallback = (recaptchaResponse) => {
    this.setState({
      verified: true,
    });
  };

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="liveAlertPlaceholder"></div>

        <div className="block">
          <div className="contact-us__container">
            <div className="row">
              <div className="col-12 col-lg-12">
                <form
                  onSubmit={this.props.handleSubmit(this.onSubmit)}
                  className="ui form error"
                >
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <Field
                        name="Name"
                        value={this.name}
                        component={this.renderInput}
                        label={
                          <FormattedMessage
                            id="contactUs.name"
                            defaultMessage="Twoje imie"
                          />
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <Field
                        name="Email"
                        value={this.email}
                        component={this.renderEmail}
                        label={
                          <FormattedMessage
                            id="contactUs.email"
                            defaultMessage="Email"
                          />
                        }
                      />
                    </div>
                  </div>
                  <Field
                    name="Subject"
                    value={this.subject}
                    component={this.renderInput}
                    label={
                      <FormattedMessage
                        id="contactUs.subject"
                        defaultMessage="Temat"
                      />
                    }
                  />
                  <Field
                    name="Message"
                    value={this.message}
                    component={this.renderTextArea}
                    label={
                      <FormattedMessage
                        id="contactUs.message"
                        defaultMessage="Wiadomosc"
                      />
                    }
                  />
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={this.captchaRef}
                  />
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <button
                        className="btn send_btn theme_btn"
                        id="liveToastBtn"
                      >
                        <FormattedMessage
                          id="contactUs.sendMessage"
                          defaultMessage="Wy�lij wiadomo��"
                        />
                      </button>
                    </div>
                    <div className="form-group col-md-6"></div>
                  </div>
                  <div className="toast-container position-static">
                    <div className="toast-container position-fixed bottom-0 end-0 p-3">
                      <div
                        id="liveToastSuccess"
                        className="toast"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                      >
                        <div className="toast-header">
                          <strong className="me-auto">Udało się</strong>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="toast"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="toast-body">
                          Wiadomość wysłano, dziękujemy!
                        </div>
                      </div>
                    </div>

                    <div className="toast-container position-fixed bottom-0 end-0 p-3">
                      <div
                        id="liveToastWarn"
                        className="toast"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                      >
                        <div className="toast-header">
                          <strong className="me-auto">Uwaga</strong>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="toast"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="toast-body">Coś poszło nie tak</div>
                      </div>
                    </div>

                    <div className="toast-container position-fixed bottom-0 end-0 p-3">
                      <div
                        id="liveToastInfo"
                        className="toast"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                      >
                        <div className="toast-header">
                          <strong className="me-auto">Informacja</strong>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="toast"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="toast-body">
                          Proszę o weryfikację czy jesteś człowiekiem :)
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.Name) {
    errors.Name = "Error";
  }
  if (!formValues.Email) {
    errors.Email = "Error";
  }
  if (!formValues.Subject) {
    errors.Subject = "Error";
  }
  if (!formValues.Message) {
    errors.Message = "Error";
  }
  return errors;
};

const formWrapped = reduxForm({
  form: "leaveMessage",
  validate: validate,
})(Form);

export default formWrapped;
