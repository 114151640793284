import React, { Component } from "react";
import { BrowserRouter, Route, Routes, Router } from "react-router-dom";
import Home from "./Home";
import { connect } from "react-redux";
import languages from "./i18n";
import { localeChange } from "./store/locale";
import { createBrowserHistory } from "history";
import { IntlProvider } from "react-intl";

class App extends Component {
  render() {
    //const history = createBrowserHistory();
    const { locale, auth } = this.props;
    const { messages, direction } = languages[locale];
    return (
      <IntlProvider locale={locale} messages={messages}>
        <React.Fragment>
          <BrowserRouter>
            <Routes createBrowserHistory={createBrowserHistory}>
              <Route exact path="/" element={<Home />} />
            </Routes>
          </BrowserRouter>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.locale,
});

const mapDispatchToProps = {
  localeChange,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
