import React, { Component } from "react";
import { Link } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import { FormattedMessage } from "react-intl";
import { FacebookProvider, Profile } from "react-facebook";
import { faStore, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { Banner } from "./Banner";

class News3 extends Component {
  render() {
    const divStyle = {
      backgroundColor: "#fafafa",
      height: "120%",
      color: "#797979",
    };

    return (
      <section className="contact-area" id="news" style={divStyle}>
        <div className="container">
          <div className="text-center mb_60">
            <h2 style={{ color: "black" }} className="title_h2 mb_0">
              <FormattedMessage id="banner.dscr.2" defaultMessage="" />
            </h2>
            <p className="title_h4 mb_1">
              <FormattedMessage id="banner.dscr.3" defaultMessage="" />
            </p>
            <p className="title_h4 mb_1">
              <FormattedMessage id="banner.dscr.4" defaultMessage="" />
            </p>
          </div>
          <Zoom top cascade>
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div
                  className="work_item wow fadeInUp text-center"
                  data-wow-delay="0.1s"
                >
                  <i className="text-center">
                    <FontAwesomeIcon icon={faStore} />
                  </i>
                  <h2 className="t_color">Godziny otwarcia</h2>
                  <p>
                    <FormattedMessage id="news.monday" defaultMessage="" /> -{" "}
                    <FormattedMessage id="news.friday" defaultMessage="" />{" "}
                    <span>09:00-19:00</span>
                  </p>
                  <p>
                    <FormattedMessage id="news.saturday" defaultMessage="" />{" "}
                    <span>10:00-14:00</span>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div
                  className="work_item wow fadeInUp text-center"
                  data-wow-delay="0.1s"
                >
                  <i className="text-center">
                    <FontAwesomeIcon icon={faPhone} />
                  </i>
                  <h2 className="t_color">Kontakt</h2>
                  <p>
                    <FormattedMessage
                      id="contact.info.phone.1"
                      defaultMessage=""
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="contact.info.email.1"
                      defaultMessage=""
                    />
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div
                  className="work_item wow fadeInUp text-center"
                  data-wow-delay="0.1s"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div
                        className="fb-page"
                        data-href="https://www.facebook.com/przychodniaweterynaryjnakozanowska/"
                        data-tabs=""
                        data-width="380"
                        data-height=""
                        data-small-header="false"
                        data-adapt-container-width="false"
                        data-hide-cover="false"
                        data-show-facepile="false"
                      >
                        <blockquote
                          cite="https://www.facebook.com/przychodniaweterynaryjnakozanowska/"
                          className="fb-xfbml-parse-ignore"
                        >
                          <a href="https://www.facebook.com/przychodniaweterynaryjnakozanowska/">
                            Przychodnia Weterynaryjna Kozanowska
                          </a>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Zoom>
        </div>
      </section>
    );
  }
}
export default News3;
