import React, { Component } from "react";
import Zoom from "react-reveal/Zoom";
import { FormattedMessage } from "react-intl";

const doc_0 = require("../image/app/About/doctor1.png");
const doc_1 = require("../image/app/About/doctor1.png");

class About2 extends Component {
  render() {
    // const BorderRadius = {
    //   width: 200,
    //   height: 200,
    //   backgroundColor: "blue",
    //   borderRadius: 300,
    // };

    return (
      <section className="contact-area" id="about">
        <div className="container">
          <h1 className="card-tittle">
            <FormattedMessage id="about.header" defaultMessage="" />
          </h1>
          <br />
          <div>
            <Zoom>
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                  <div
                    className="work_item wow fadeInUp text-center"
                    data-wow-delay="0.1s"
                  >
                    <div>
                      <img
                        src={doc_0}
                        alt=""
                        style={{ width: 200, height: 200 }}
                      />
                    </div>
                    <h2 className="t_color">
                      <FormattedMessage id="about.doc.1.1" defaultMessage="" />
                    </h2>
                    <h5>
                      <FormattedMessage id="about.doc.1.2" defaultMessage="" />
                    </h5>
                    <h5>
                      <FormattedMessage id="about.doc.1.3" defaultMessage="" />
                    </h5>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div
                    className="work_item wow fadeInUp text-center"
                    data-wow-delay="0.1s"
                  >
                    <div>
                      <img
                        src={doc_1}
                        alt=""
                        style={{ width: 200, height: 200 }}
                      />
                    </div>
                    <h2 className="t_color">
                      <FormattedMessage id="about.doc.2.1" defaultMessage="" />
                    </h2>
                    <h5>
                      <FormattedMessage id="about.doc.2.2" defaultMessage="" />
                    </h5>
                    <h5>
                      <FormattedMessage id="about.doc.2.3" defaultMessage="" />
                    </h5>
                  </div>
                </div>
              </div>
            </Zoom>
          </div>
        </div>
      </section>
    );
  }
}
export default About2;
