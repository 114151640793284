// third-party
import { applyMiddleware, compose } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import version from "./version";

function load() {
  let state;

  try {
    state = localStorage.getItem("state");

    if (typeof state === "string") {
      state = JSON.parse(state);
    }

    if (state && state.version !== version) {
      state = undefined;
    }
  } catch (error) {
    console.error(error);
  }

  return state || undefined;
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore({ reducer: rootReducer });

export default store;
